<template>
  <div>
    <b-modal
      id="modal-update-deposit-type"
      cancel-variant="outline-secondary"
      :ok-title="$t('Confirm')"
      :cancel-title="$t('Close')"
      ok-variant="success"
      centered
      modal-class="modal-success"
      :title="$t('Update Deposit Type')"
      @ok="confirm"
      @hidden="resetModal()"
    >
      <!-- form -->
      <validation-observer ref="updateDepositTypeModal">
        <b-form method="POST" @submit.prevent="validationForm">
          <b-form-group label-for="id">
            <label class="mb-1">{{ $t("id") }}</label>
            <validation-provider
              #default="{ errors }"
              name="id"
              rules="required"
            >
              <b-form-input
                id="id"
                v-model="depositType.id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                disabled
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label-for="name">
            <label class="mb-1">{{ $t("Name") }}</label>
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
            >
              <b-form-input
                id="name"
                v-model="depositType.name"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label-for="name_en">
            <label class="mb-1">{{ $t("English Name") }}</label>
            <validation-provider
              #default="{ errors }"
              name="name_en"
              rules="required"
            >
              <b-form-input
                id="name_en"
                v-model="depositType.name_en"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label-for="logo">
            <label for="">
              {{ $t("logo") }}
              <span class="text-secondary">(Không > 10Mb)</span></label
            >
            <validation-provider
              #default="{ errors }"
              name="logo"
              rules=""
            >
              <b-form-file
                v-model="depositType.logo"
                input-id="logo"
                accept="image/jpeg,image/png,image/gif,image/svg,image/jpg"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <b-img
              :src="`${depositType.showImg ? depositType.showImg : ''}`"
              fluid
              style="width: 150px"
              class="mr-2 rounded"
            />
          </b-form-group>
          <b-button
            v-show="depositType.logo"
            variant="outline-danger"
            class="btn-sm btn-icon"
            @click="depositType.logo = null"
          >
            <feather-icon icon="XIcon" />
          </b-button>

          <b-form-group label-for="status">
            <label class="mb-1 d-block">Status</label>
            <b-form-radio
              v-model="depositType.status"
              name="status"
              value="1"
              inline
            >
              Active
            </b-form-radio>
            <b-form-radio
              v-model="depositType.status"
              name="status"
              value="2"
              inline
            >
              Inactive
            </b-form-radio>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import {
  BLink,
  BRow,
  BCol,
  BTabs,
  BTab,
  BForm,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormRadio,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BModal,
  VBModal,
  BInputGroupAppend,
  BFormTextarea,
  BBadge,
  BImg,
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import baseUrl from "@/libs/base-url";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import router from "@/router";

export default {
  components: {
    BLink,
    BRow,
    BCol,
    BTabs,
    BTab,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormRadio,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BModal,
    BFormTextarea,
    BBadge,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BImg,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      depositType: {
        // id: "",
        id: "",
        name: "",
        name_en: "",
        status: "",
        logo: "",
        showImg: "",
      },
    };
  },
  props: {
    depositTypeDetail: {
      type: Object,
      default: null,
    },
    // typeOptions: {
    //   type: Array,
    //   required: true,
    // },
  },
  watch: {
    depositTypeDetail: {
      handler(newVal) {
        if (newVal) {
          this.depositType = {
            // id: newVal.id,
            id: newVal.id,
            name: newVal.name,
            name_en: newVal.name_en,
            logo: null,
            showImg: baseUrl + newVal.logo,
            status: newVal.status,
          };
        }
      },
      deep: true,
    },
  },
  methods: {
    validationForm() {
      this.$refs.updateDepositTypeModal.validate().then((success) => {
        if (success) {
          this.updateDepositTypeData(this.depositType);
        }
      });
    },
    async updateDepositTypeData(depositTypeData) {
      await this.$store
        .dispatch("payment-deposit-type/updateDepositType", depositTypeData)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.code == "00") {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message || "success",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              this.$emit("refetch-data");
              this.$nextTick(() => {
                setTimeout(() => {
                  this.$bvModal.hide("modal-update-deposit-type");
                  this.resetModal();
                }, "500");
              });
              this.$emit("refetch-data");
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
          }
        });
    },
    resetModal() {
      this.depositType = {
        id: "",
        name: "",
        name_en: "",
        status: "",
        logo: null,
      };
      this.$emit("update:depositTypeDetail", null);
    },
    confirm(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.validationForm();
    },
  },
  setup() {
    return {
      baseUrl,
    };
  },
};
</script>